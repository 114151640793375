@import url('https://fonts.cdnfonts.com/css/noto-sans');

                
.App {
  color: #FF8BFF;
  background-color: #193718;
  min-height: 100vh;
  font-family: 'Noto Sans', sans-serif;
                                                
}


.App-header {
  color: #FF8BFF;
    background-color: #193718;
    --fg: 255,139,255;
    --bg: 25,55,24;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  
  ul li{
    display: inline-block;
    margin: 0 20px;
    font-size: 0.9em;
    font-weight: bold;
    color: #FF8BFF;

  }
}

.dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}

section {
  padding: 20px;
  margin: 20px 40px;
  font-size: 1.3em;
  line-height: 1.5em;
  display: flex;

  div {
    padding: 10px
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
